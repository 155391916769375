import React, { useEffect, useState } from "react";
import { MainLayout } from "../layout/mainLayout";
import TextField from "@mui/material/TextField";
import Grid2 from "@mui/material/Unstable_Grid2";
import { MuiTimePicker } from "../framework/muiTimePicker";
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Button,
    Checkbox,
    Typography,
    Tooltip,
    IconButton
} from "@mui/material";
import { Translations } from "../../models/translations";
import {
    getEmployeeGroupItems,
    getFunctionSettings,
    saveFunctionSettings,
} from "../../services/employeeGroupService";
import { showError } from "../storage/storageUtils";
import { EmployeeGroupItem } from "../../models/employeeGroup/employeeGroupItem";
import { TESCalculationParameters, ShiftEffectiveDateLogic } from "../../models/calculation/TESCalculationParameters";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import i18n from "i18next";
import * as CustomStore from "../../framework/customStore";
import * as StoreActions from "../../models/store/storeActions";
import { CalcOvertimes } from "./CalcOvertimes";
import { LunchBreak } from "./LunchBreak";
import MuiSelect from "../framework/muiSelect";
import InfoIcon from "@mui/icons-material/Info";
import { Rounding } from "./Rounding";
import { NumberInput } from "../framework/numberInput";
import { CalcDailyAllowance } from "./CalcDailyAllowance";
import { LoadingIndicator } from "../framework/loadingIndicatorNew";

type optionTp = { label: string; id: string };

export const CalculationMain = () => {
    const [settings, setSettings] = useState<TESCalculationParameters>();
    const [employeeGroups, setEmployeeGroups] = useState<optionTp[]>([]);
    const [employeeGroup, setEmployeeGroup] = useState<optionTp>({
        label: "",
        id: "",
    });
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [searchIsOn, setSearchIsOn] = useState(true);
    const [started, setStarted] = useState(false);

    const {
        dayWorkStartTime,
        eveningWorkStartTime,
        nightWorkStartTime,
        paidBreak,
        paidWaiting,
        isSundayCalculationOn,
        isSaturdayCalculationOn,
        isEveningCalculationOn,
        isNightCalculationOn,
        overtimePeriodBoundaries: overtimeBoundaries,
        overtimeWeekBoundaries,
        overtimeDailyBoundaries,
        overtimeSaturdayBoundaries,
        dailyAllowanceBoundaries,
        isMonthlyOvertimeCalculationOn,
        isGrandHolidayDayCalculationOn,
        shiftEffectiveDateLogic,
        isFixedAdditionOn,
    } = settings || {};

    const handleChange = (attr: string, value) => {
        setSettings({ ...settings, [attr]: value });
    };

    useEffect(() => {
        if (!started) {
            getEmployeeGroupItems(9999, 1, "", "Name", true)
                .then((response) => {
                    fillEmployeeGroups(response.items);
                    setStarted(true);
                })
                .catch(showError);
        }
    }, []);

    useEffect(() => {
        onEmployeeChanged(employeeGroup);
    }, [employeeGroup]);

    const fillEmployeeGroups = (items: EmployeeGroupItem[]) => {
        const groups = items.map((x) => ({ label: x.name, id: x.id }));
        setEmployeeGroups(groups);
        setEmployeeGroup(groups[0]);
    };

    const onEmployeeChanged = (value: optionTp) => {
        setSaveButtonDisabled(true);
        setSearchIsOn(true);

        if (value?.id) {
            getFunctionSettings(value.id)
                .then((response) => {
                    setSettings(response);
                })
                .catch(showError)
                .finally(() => {
                    setSaveButtonDisabled(false);
                    setSearchIsOn(false);
                });
        }
    };

    const validate = (): boolean => {
        if (
            [
                overtimeBoundaries,
                overtimeWeekBoundaries,
                overtimeDailyBoundaries,
                overtimeSaturdayBoundaries,
                dailyAllowanceBoundaries,
            ].some(
                (b) =>
                    b.length === 2 &&
                    b.find((v) => v.boundary === 50)?.boundaryValue >
                        b.find((v) => v.boundary === 100)?.boundaryValue
            )
        ) {
            CustomStore.customStore.dispatch(
                StoreActions.showErrorMessage(translate("CHECKBOUNDARYVALUE"))
            );
            return false;
        }

        if (isMonthlyOvertimeCalculationOn && overtimeBoundaries.length > 0) {
            CustomStore.customStore.dispatch(
                StoreActions.showErrorMessage(
                    translate("CheckMonthlyAndPeriodOvertime")
                )
            );
            return false;
        }

        return true;
    };

    const saveParameters = () => {
        if (validate()) {
            if (employeeGroup) {
                setSaveButtonDisabled(true);
                setSearchIsOn(true);

                saveFunctionSettings(employeeGroup.id, settings)
                    .then(() => {
                        store.customStore.dispatch(
                            storeActions.showSuccessMessage(
                                Translations.SaveSuccess
                            )
                        );
                    })
                    .catch(() => {
                        store.customStore.dispatch(
                            storeActions.showErrorMessage(
                                translate("unexpectedError")
                            )
                        );
                    })
                    .finally(() => {
                        setSaveButtonDisabled(false);
                        setSearchIsOn(false);
                    });
            }
        }
    };

    const translate = (str: string): string => {
        //i18n using not well done interface so.
        /*eslint-disable   @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call */
        return i18n.t(str);
    };

    return (
        <MainLayout
            topComponent={
                <Grid2
                    container
                    direction="row"
                    paddingLeft={2}
                    alignItems={"center"}
                >
                    <Autocomplete
                        className="field-width-normal"
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        renderInput={(
                            params: AutocompleteRenderInputParams
                        ) => (
                            <TextField
                                {...params}
                                label={Translations.EmployeeGroup}
                            />
                        )}
                        onChange={(event, value) => setEmployeeGroup(value)}
                        options={employeeGroups}
                        disableClearable
                        autoSelect
                        disablePortal
                        disabled={searchIsOn}
                        value={employeeGroup}
                    />
                    <Button
                        style={{ marginLeft: "3rem" }}
                        id="save_button"
                        variant="save"
                        onClick={() => saveParameters()}
                        key="btn-save"
                        disabled={saveButtonDisabled}
                    >
                        {Translations.Save}
                    </Button>
                </Grid2>
            }
        >
            <>
                <LoadingIndicator loading={searchIsOn} />
                {!settings ? null : (
                    <Grid2 container>
                        <Grid2 container direction="column" p={3} spacing={2}>
                            <Grid2>
                                <Typography variant="h3">
                                    {translate("workShifts")}
                                </Typography>
                            </Grid2>
                            <Grid2>
                                <NumberInput
                                    id="paidBreak"
                                    value={paidBreak}
                                    className="field-width-normal"
                                    label={translate("paidBreak")}
                                    onChange={(val) =>
                                        handleChange("paidBreak", val)
                                    }
                                    endAdornment="min"
                                />
                            </Grid2>
                            <Grid2>
                                <NumberInput
                                    id="paidWaiting"
                                    value={paidWaiting}
                                    className="field-width-normal"
                                    label={translate("paidWaiting")}
                                    onChange={(val) =>
                                        handleChange("paidWaiting", val)
                                    }
                                    endAdornment="min"
                                />
                            </Grid2>
                            <Grid2>
                                <MuiSelect
                                    label={translate("shiftEffectiveDateLabel")}
                                    className="field-width-normal"
                                    value={shiftEffectiveDateLogic}
                                    onChange={(val) =>
                                        handleChange(
                                            "shiftEffectiveDateLogic",
                                            val
                                        )
                                    }
                                    options={Object.keys(
                                        ShiftEffectiveDateLogic
                                    )
                                        .filter((key) => isNaN(parseInt(key)))
                                        .map((key) => ({
                                            value: ShiftEffectiveDateLogic[key],
                                            label: translate(
                                                `shiftEffectiveDateOption.${key}`
                                            ),
                                        }))}
                                />
                            </Grid2>
                        </Grid2>
                        <LunchBreak
                            translate={translate}
                            handleChange={handleChange}
                            settings={settings}
                            setSettings={setSettings}
                        />
                        <Grid2 container direction="column" spacing={2} p={3}>
                            <Grid2>
                                <Typography variant="h3">
                                    {translate("dayAdditions")}
                                </Typography>
                            </Grid2>
                            <Grid2>
                                <label className={"field-width-normal"}>
                                    <Checkbox
                                        id="SATURDAYWORKON"
                                        checked={isSaturdayCalculationOn}
                                        onChange={(event) =>
                                            handleChange(
                                                "isSaturdayCalculationOn",
                                                event.target.checked
                                            )
                                        }
                                    />
                                    {translate("SATURDAYWORKON")}
                                </label>
                            </Grid2>
                            <Grid2>
                                <label className={"field-width-normal"}>
                                    <Checkbox
                                        id="SUNDAYWORKON"
                                        checked={isSundayCalculationOn}
                                        onChange={(event) =>
                                            handleChange(
                                                "isSundayCalculationOn",
                                                event.target.checked
                                            )
                                        }
                                    />
                                    {translate("SUNDAYWORKON")}
                                </label>
                            </Grid2>
                            <Grid2>
                                <label className={"field-width-normal"}>
                                    <Checkbox
                                        id="GRANDHOLIDAYON"
                                        checked={isGrandHolidayDayCalculationOn}
                                        onChange={(event) =>
                                            handleChange(
                                                "isGrandHolidayDayCalculationOn",
                                                event.target.checked
                                            )
                                        }
                                    />
                                    {translate("grandHolidays")}
                                    <Tooltip
                                        title={translate(
                                            "grandHolidayHelper.day"
                                        )}
                                    >
                                        <IconButton disableRipple>
                                            <InfoIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </label>
                            </Grid2>
                        </Grid2>
                        <Grid2 container direction="column" spacing={2} p={3}>
                            <Grid2>
                                <Typography variant="h3">
                                    {translate("otherAdditions")}
                                </Typography>
                            </Grid2>
                            <Grid2>
                                <label className={"field-width-normal"}>
                                    <Checkbox
                                        id="FIXEDADDITIONON"
                                        checked={isFixedAdditionOn}
                                        onChange={(event) =>
                                            handleChange(
                                                "isFixedAdditionOn",
                                                event.target.checked
                                            )
                                        }
                                    />
                                    {translate("fixedAddition")}
                                    <Tooltip
                                        title={translate("fixedAdditionHelp")}
                                    >
                                        <IconButton disableRipple>
                                            <InfoIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </label>
                            </Grid2>
                            <Grid2>
                                <label className={"field-width-normal"}>
                                    <Checkbox
                                        id="EVENINGCALCULATIONON"
                                        checked={isEveningCalculationOn}
                                        onChange={(event) =>
                                            handleChange(
                                                "isEveningCalculationOn",
                                                event.target.checked
                                            )
                                        }
                                    />
                                    {translate("EVENINGCALCULATIONON")}
                                </label>
                            </Grid2>
                            <Grid2 mb={1}>
                                <label className={"field-width-normal"}>
                                    <Checkbox
                                        id="NIGHTCALCULATIONISON"
                                        checked={isNightCalculationOn}
                                        onChange={(event) =>
                                            handleChange(
                                                "isNightCalculationOn",
                                                event.target.checked
                                            )
                                        }
                                    />
                                    {translate("NIGHTCALCULATIONISON")}
                                </label>
                            </Grid2>
                            {isEveningCalculationOn ? (
                                <Grid2>
                                    <MuiTimePicker
                                        label={translate(
                                            "EVENINGWORKSTARTTIME"
                                        )}
                                        value={eveningWorkStartTime}
                                        onChange={(newValue) =>
                                            handleChange(
                                                "eveningWorkStartTime",
                                                newValue
                                            )
                                        }
                                    />
                                </Grid2>
                            ) : null}
                            {isEveningCalculationOn || isNightCalculationOn ? (
                                <Grid2>
                                    <MuiTimePicker
                                        label={[
                                            isEveningCalculationOn
                                                ? translate(
                                                      "EVENINGWORKENDTIME"
                                                  )
                                                : null,
                                            isNightCalculationOn
                                                ? translate(
                                                      "NIGHTWORKSTARTTIME"
                                                  )
                                                : null,
                                        ]
                                            .filter(Boolean)
                                            .join(" / ")}
                                        value={nightWorkStartTime}
                                        onChange={(newValue) =>
                                            handleChange(
                                                "nightWorkStartTime",
                                                newValue
                                            )
                                        }
                                    />
                                </Grid2>
                            ) : null}
                            {isNightCalculationOn ? (
                                <Grid2>
                                    <MuiTimePicker
                                        label={translate("DAYWORKSTARTTIME")}
                                        value={dayWorkStartTime}
                                        onChange={(newValue) =>
                                            handleChange(
                                                "dayWorkStartTime",
                                                newValue
                                            )
                                        }
                                    />
                                </Grid2>
                            ) : null}
                        </Grid2>
                        <CalcOvertimes
                            translate={translate}
                            handleChange={handleChange}
                            settings={settings}
                            setSettings={setSettings}
                        />
                        <CalcDailyAllowance
                            translate={translate}
                            settings={settings}
                            setSettings={setSettings}
                        />
                        <Rounding
                            translate={translate}
                            handleChange={handleChange}
                            settings={settings}
                            setSettings={setSettings}
                        />
                    </Grid2>
                )}
            </>
        </MainLayout>
    );
};
